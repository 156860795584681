<template>
  <div class="container">
    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">

      <div class="col-12 col-md-auto" style="max-width: 450px">


        <div v-html="template"></div>

        <div>
          <div class="row mt-4 justify-content-center">
            <div class="col-auto ">
              <div class="font-weight-bold"> กรุณาติดต่อ</div>
              <div class="small"> Tel. 092-249-4999 (จันทร์-ศุกร์ 10.00 - 19.00 น.)</div>
              <div class="small"> Facebook page : <a class="text-kss font-weight-bold"
                                                     href="https://m.me/stockradars"
                                                     target="_blank">StockRadars </a>
              </div>


              <a href="https://www.facebook.com/messages/t/191368201022860" target="_blank"
                 class="mt-3 w-100 btn btn-primary mb-3">
                ส่งข้อความ
              </a>

            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.message = this.$route.query.message;

    switch (this.message) {
      case 'expired' :
        this.template = `<div class="sub-title text-center">หมดเวลาในการชำระเงิน <br> กรุณาลองใหม่อีกครั้ง</div>`;
        break;
      case 'not-allowed' :
        this.template = `<div class="sub-title text-center"> เฉพาะลูกค้าเฉพาะกลุ่ม </div>`;
        break;
      case 'out-of-stock' :
        this.template = `<div class="sub-title text-center">  สินค้าหมด </div>`;
        break;
      case 'out-of-time' :
        this.template = `<div class="sub-title text-center">  เริ่มในเร็วๆนี้ </div>`;
        break;
      default:
        this.template = `<div class="sub-title text-center">ไม่สามารถทำรายการได้ <br> กรุณาลองใหม่อีกครั้ง</div>`;
        break;
    }

  },
  data() {
    return {
      template: '',
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
</style>

